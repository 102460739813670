import { ContentBlockType } from '@tytapp/content';
import { PollBlock } from './poll-block';

export * from './poll-block';

export const POLL_BLOCK_TYPE: ContentBlockType<PollBlock> = {
    id: 'poll',
    label: 'Poll',
    icon: 'ballot',
    preview: {
        selectionMode: 'preview'
    },
    previewSettings: {
        fade: true
    },
    deferredEditorComponent: () => import('./poll-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./poll-block.view').then(m => m.ViewComponent)
};
