import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule as ACommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TYTCommonModule } from '../common';
import { ApiModule, ApiPoll, ApiSlug, PollsApi } from '../api';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PollSingleComponent } from './poll-single/poll-single.component';
import { CommonUiModule } from '@tytapp/common-ui';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PollsService } from './polls.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WhatIsAMissionComponent } from './what-is-a-mission/what-is-a-mission.component';
import { SlugService, SlugType } from '@tytapp/slugs';
import { DevModule } from '@tytapp/app/dev';
import { ContentBlocksService, ContentModule } from '@tytapp/content';
import { PETITION_BLOCK_TYPE } from './petition-block';
import { POLL_BLOCK_TYPE } from './poll-block';
import {PETITION_PROGRESS_BLOCK_TYPE} from './petition-progress-block';
import { ChatModule } from '@tytapp/chat';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const COMPONENTS = [
    PollSingleComponent,
    WhatIsAMissionComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        HttpClientModule,
        FormsModule,
        ACommonModule,
        CommonUiModule,
        ContentModule,
        ChatModule,
        RouterModule,
        ImageCropperModule,
        TYTCommonModule,
        ApiModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSnackBarModule,
        DevModule
        // routing
    ],
    providers: [
        PollsService
    ],
    exports: COMPONENTS
})
export class PollsModule {
    private polls = inject(PollsService);
    private pollsApi = inject(PollsApi);

    constructor() {
        ContentBlocksService.register(PETITION_BLOCK_TYPE);
        ContentBlocksService.register(POLL_BLOCK_TYPE);
        ContentBlocksService.register(PETITION_PROGRESS_BLOCK_TYPE);

        const slugType = (type: string, friendlyName: string, getUrl: (slug: ApiSlug) => string): SlugType<ApiPoll> => {
            return {
                type,
                friendlyName,
                getUrl,
                getContent: async slug => await this.pollsApi.getPoll(slug.slug).toPromise(),
                getMetadataForContent: async content => ({
                    title: content.title,
                    url: this.polls.getPollUrl(content)
                }),
            }
        };
        SlugService.registerType(slugType('Poll', 'Poll', slug => `/polls/${slug.slug}`));
        SlugService.registerType(slugType('ChooseOnePoll', 'Poll (Choose One)', slug => `/polls/${slug.slug}`));
        SlugService.registerType(slugType('Petition', 'Petition', slug => `/campaigns/${slug.slug}`));
    }

    static forRoot(): ModuleWithProviders<PollsModule> {
        return {
            ngModule: PollsModule,
            providers: []
        };
    }
}
