@if (visible) {
  @if (!poll) {
    @if (expectedType === 'Petition') {
      <div class="poll-background placeholder" aria-hidden="true">
        <div class="gradient"></div>
      </div>
      <div class="poll-description" aria-hidden="true">
        <div class="status-count">
          <div class="open status"><span class="placeholder"></span></div>
          <div class="vote-count"><span class="placeholder"></span></div>
        </div>
        <span class="placeholder"></span>
        <div class="description">
          <span class="placeholder"></span>
        </div>
        <div class="author">
          <span class="placeholder"></span>
        </div>
        <div class="share">
          <span class="placeholder"></span>
        </div>
      </div>
    }
    @if (expectedType === 'ChooseOnePoll') {
      <div class="poll-background placeholder" aria-hidden="true">
        <div class="gradient"></div>
      </div>
      <div class="poll-description" aria-hidden="true">
        <div class="status-count">
          <div class="status open">
            <span class="placeholder" style="width: 5em;"></span>
          </div>
          <div class="vote-count"><span class="placeholder" style="width: 5em;"></span></div>
        </div>
        <span class="placeholder"></span>
        <div class="description">
          <span class="placeholder"></span>
        </div>
        <div class="author">
          <span>
            <span class="placeholder"></span>
          </span>
        </div>
      </div>
      <div aria-hidden="true">
        <div class="poll">
          <div class="choices">
            @for (n of [].constructor(3); track n) {
              <a mat-button class="poll-item">
                <div class="image">
                  <div class="image-overlay"></div>
                </div>
                <div class="body"><span class="placeholder"></span></div>
              </a>
            }
          </div>
          <small class="tabulation-note">
            <span class="placeholder"></span>
          </small>
        </div>
        <div class="signature-count">
          <span class="placeholder"></span>
        </div>
        <div class="signature-c2a">
          <span class="placeholder"></span>
        </div>
        <div class="options">
          <span class="placeholder" style="width: 5em;"></span>
          <span class="placeholder" style="width: 2em;"></span>

          <div class="spacer"></div>

          <span class="placeholder"></span>
          <span class="placeholder"></span>
          <span class="placeholder"></span>
        </div>
      </div>
      <div class="share" aria-hidden="true">
        <span class="placeholder"></span>
      </div>
    }
  }
  @if (poll) {
    @if (poll.image && showImage) {
      <div class="poll-background" [responsiveBackgroundImage]="poll.image">
        <div class="gradient"></div>
      </div>
    }
    <div class="poll-description">
      <div class="status-count">
        @if (poll.membership_required) {
          <tyt-plus-tag></tyt-plus-tag>
          &nbsp;
          &nbsp;
        }
        @if (showStatus) {
          <div class="status {{poll.status === 'published' ? 'open' : ''}}">
            @if (!openForVoting) {
              @if (!opensLater) {
                @if (!closedEarlier) {
                  Closed
                }
                @if (closedEarlier) {
                  Closed
                }
              }
              @if (opensLater) {
                Opens at {{this.poll.opens_at | date : 'shortDate'}}
              }
            }
            @if (openForVoting) {
              {{votingOpenLabel}}
            }
          </div>
        }
          @if (showVoteCount && totalVotes > 0) {
            <div class="vote-count">{{totalVotes | number}} {{votesLabel}}</div>
          }
        </div>
      @if (showTitle) {
        <div class="poll-title">
          @if(linkTitle) {
            <a routerLink="{{pollUrl}}">{{poll?.title}}</a>
          } @else {
            @if(mini) {
              <a routerLink="{{pollUrl}}">{{poll?.title}}</a>
            } @else {
              {{poll?.title}}
            }
          }
        </div>
      }
      @if (showDescription ?? !mini) {
        @if (shouldShowDescription) {
          <div
            #descriptionBox
            class="description cms-content"
            [class.short]="useShortDescription"
            [innerHTML]="poll.description | markdownToHtml | trustHtml"
          ></div>
        }
        @if (useShortDescription && showReadMore) {
          <div>
            <a routerLink="{{pollUrl}}">Read more</a>
          </div>
        }
      }
      <div class="author">
        <span>
          @if (poll.opens_at && poll.closes_at) {
            ·
            Opens {{poll.opens_at | date : 'shortDate'}} - {{poll.closes_at | date : 'shortDate'}}
          }
          @if (poll.opens_at && !poll.closes_at) {
            ·
            Opens {{poll.opens_at | date : 'shortDate'}}
            {{poll.closes_at | date}}
          }
          @if (!poll.opens_at && poll.closes_at) {
            ·
            Closes {{poll.opens_at | date : 'shortDate'}}
          }
          @if (user && user.staff && allowEdit) {
            ·
            <a routerLink="{{pollUrl}}/edit">Edit</a>
          }
        </span>
      </div>
    </div>
    @if (!mini || poll.type !== 'Petition') {
      @if (showPollQuestions && questionsToShow.length > 0) {
        @for (question of questionsToShow; track question) {
          <div>
            <div class="poll">
              @if (question.body) {
                <div class="question-body">
                  {{question.body}}
                </div>
              }

              <div class="choose-amount">
                {{ chooseAmountMessageForQuestion(question) }}
              </div>

              @if (showChoices && showPollChoices) {
                <div class="choices">
                  @if (resultsAvailable || voted) {
                    @for (item of question.choices; track item) {
                      <div mat-button class="poll-item after-voting"
                        [class.selected]="!resultsAvailable && isSelected(question.id, item.id)"
                        [class.has-results]="resultsAvailable"
                        [class.voted]="voted && isSelected(question.id, item.id)"
                        >
                        @if (item.image) {
                          <div class="image" [responsiveBackgroundImage]="item.image">
                            <div class="image-overlay"></div>
                          </div>
                        }
                        <div class="voted-tag">Your vote</div>
                        <div class="body">{{ item.body }}</div>
                        @if (voted || showResultsWithoutVote) {
                          <div class="result-container">
                            <div class="results" [class.active]="voted || showResultsWithoutVote">
                              <div class="result-value">{{getChoiceVoteCount(question, item)}}</div>
                              <div class="result-bar" [class.ready]="resultsReady"
                                [style.width]="getPercentage(item.votes_count, question.total_votes)+'%'"></div>
                            </div>
                              @if (question.max_choices_count === 1) {
                                <div class="result-count">
                                  {{item.votes_count | number}} votes
                                </div>
                              }
                          </div>
                        }
                      </div>
                    }
                  } @else {
                    @for (item of question.choices; track item) {
                      <a mat-button class="poll-item"
                        [class.selected]="!voted && isSelected(question.id, item.id)"
                        (click)="selectChoice(question.id, item.id)"
                        >
                        @if (item.image) {
                          <div class="image" [responsiveBackgroundImage]="item.image">
                            <div class="image-overlay"></div>
                          </div>
                        }
                        <div class="body">{{ item.body }}</div>
                      </a>
                    }
                  }
                </div>
              }
              @if (poll.type !== 'Petition' && showDisclaimer) {
                <small class="tabulation-note">
                  The count of poll votes is not tabulated in real time; your vote has been counted and will be included
                  in the total shortly.
                </small>
              }
            </div>
            @if (poll.type === 'Petition') {
              <div class="signature-count">
                @if (totalVotes == 0) {
                  Be the first to sign!
                }
                @if (totalVotes > 0) {
                  {{totalVotes | number}}
                  @if (totalVotes == 1) {
                    signature
                  }
                  @if (totalVotes > 1) {
                    signatures
                  }
                }
              </div>
              <div class="signature-c2a">
                @if (voted) {
                  <!-- TODO -->
                }
                @if (!voted) {
                  @if (openForVoting) {
                    Show your support by signing below.
                  }
                }
              </div>
            }
          </div>
        }
      }

      <div class="options">
        @if(showContentBadge) {
          <tyt-content-badge
            [premium]="poll.membership_required"
            [type]="poll.type === 'Petition' ? 'petition': 'poll'"
            [topic]="poll.topic"
            />
        }
        <tyt-comments-count [identifier]="commentIdentifier"></tyt-comments-count>

        <div class="spacer"></div>

        @if (canVoteAndSeeResults()) {
          <span class="message">Vote to see results</span>
        }
        @if (poll.type === 'Petition') {
          @if (user) {
            @if (privacyMode === 'showEmail') {
              {{user.profile?.first_name || user.display_name}} ({{user.email}})
            }
            @if (privacyMode === 'assurance') {
              Your information will not be shared
            }
          }
        }
        @if (poll.type !== 'Petition') {
          @if (!userVote && !poll.hide_results_before_voting && !poll.hide_results_after_voting) {

            @if (resultsAvailable) {
              <button mat-button (click)="hideResults()">
                Hide Results
              </button>
            } @else {
              <button mat-button (click)="showResultsWithoutVoting()">
                See Results
              </button>
            }
          }
          @if (poll.hide_results_before_voting && poll.hide_results_after_voting && showResultIndicator) {
            <span class="message">Results will be announced later!</span>
          }
        }

        <div class="share">
          @if (showSocialButtons) {
            <h1>Share this poll</h1>
            <tyt-social-buttons url="{{pollUrl}}"></tyt-social-buttons>
          }
        </div>

        @if (showVoteButton) {
          @if (voted) {
            <button mat-icon-button class="reload" (click)="reload()" [class.reloading]="reloading">
              <mat-icon>sync</mat-icon>
            </button>
          }
          @if (voted && poll.vote_changeable) {
            <button mat-button (click)="changeVote()">
              Change vote
            </button>
          }
          @if (!voted && userVote) {
            @if (!voted && userVote) {
              <button mat-button (click)="cancelChangeVote()">
                Cancel
              </button>
            }
            &nbsp;
            <button mat-button (click)="removeVote()">
              Remove vote
            </button>
            &nbsp;
          }
          <button mat-raised-button color="primary" class="vote {{voted ? 'voted' : ''}}" [disabled]="!isVoteValid" (click)="vote()">
            @if (!openForVoting) {
              @if (opensLater) {
                <span>
                Opens on {{poll.opens_at | date : 'shortDate'}}
              </span>
              }
              @if (!opensLater && closedEarlier) {
                <span>
                Closed on {{poll.closes_at | date : 'shortDate'}}
              </span>
              }
              @if (!opensLater && !closedEarlier) {
                <span>
                Closed
              </span>
              }
            }
            @if (openForVoting) {
              @if (userVote) {
                @if (!voted) {
                  <span>Change Vote</span>
                }
                @if (voted) {
                  <span>
                  {{votedVerb}}
                    <img src="/assets/polls/check.svg" alt="">
                </span>
                }
              }
              @if (!userVote) {
                @if (!voted) {
                  <span>
                  {{voteVerb}}
                </span>
                }
                @if (voted) {
                  <span>
                  {{votedVerb}}
                    <img src="/assets/polls/check.svg" alt="">
                </span>
                }
              }
            }
          </button>
        }

        <div class="view-all-section">
          <a routerLink="/polls">View all polls</a>
        </div>
      </div>
      @if (showComments) {
        @if (voted || closedEarlier) {
          <tyt-comments
            url="/polls/{{poll.slug}}"
            [identifier]="commentIdentifier"
            requirementType="registration"
          ></tyt-comments>
        }
        @if (!voted && !closedEarlier) {
          <h2>Comments</h2>
          <em>Vote first in order to see and post comments on this poll.</em>
          <br />
          <br />
        }
      }
    }
  }

  <tyt-staging-note title="Tools">
    <strong>Testing Options</strong>
    <div title="Enable this option to display random results.">
      <label class="key">
        Random Results:
      </label>
      @if (fakeVotes) {
        Yes
      }
      @if (!fakeVotes) {
        No
      }
      <a href="javascript:;" (click)="fakeVotes = !fakeVotes; reload()">Toggle</a>
    </div>
    <div title="Force mode (mini/non-mini/auto)">
      <label class="key">
        Mini: {{forceMini}}
      </label>
      <a href="javascript:;" (click)="forceMini = true">Force On</a> /
      <a href="javascript:;" (click)="forceMini = false">Force Off</a> /
      <a href="javascript:;" (click)="forceMini = undefined">Default</a>
    </div>
  </tyt-staging-note>
  <tyt-staging-note title="Poll (JSON)">
    <tyt-json-view [value]="poll"></tyt-json-view>
  </tyt-staging-note>
}
