import { ContentBlockType } from '@tytapp/content';
import { PetitionBlock } from './petition-block';

export * from './petition-block';

export const PETITION_BLOCK_TYPE: ContentBlockType<PetitionBlock> = {
    id: 'petition',
    label: 'Petition',
    icon: 'ballot',
    preview: {
        selectionMode: 'preview'
    },
    previewSettings: {
        fade: true
    },
    deferredEditorComponent: () => import('./petition-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./petition-block.view').then(m => m.ViewComponent)
};
