<div class="container">
  <div class="content">
    <div class="box">
      <div class="icon">
        <svg width="24" height="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.7356 1.07809V11.7943C14.4121 11.6003 14.0456 11.4997 13.679 11.4997H13.564C13.2119 11.514 12.8741 11.6218 12.5794 11.7943V1.07809C12.5794 0.481548 13.0609 0 13.6575 0C14.254 0 14.7356 0.481548 14.7356 1.07809Z"
            fill="#171921" />
            <path
              d="M21.9229 1.07809V6.82793C21.9229 7.42447 21.4413 7.90602 20.8448 7.90602H13.6575C13.0609 7.90602 12.5794 7.42447 12.5794 6.82793V1.07809C12.5794 0.481548 13.0609 0 13.6575 0H20.8448C21.4413 0 21.9229 0.481548 21.9229 1.07809Z"
              fill="#171921" />
              <path
                d="M22.9219 22.3382C22.7494 22.7407 22.3613 22.9994 21.9228 22.9994L1.07971 22.9922C0.641284 22.9922 0.25317 22.7263 0.080675 22.3238C-0.0846327 21.9213 0.0088021 21.4542 0.317856 21.1523L6.06769 15.4025C6.44143 15.0215 7.04516 14.9784 7.4764 15.3018L9.53196 16.8471L12.8381 12.9588C13.0322 12.7288 13.3125 12.5922 13.6143 12.5779C13.9018 12.5563 14.2037 12.6785 14.4193 12.8941L22.6847 21.1595C22.9938 21.4685 23.0872 21.9357 22.9219 22.3382Z"
                fill="#171921" />
              </svg>
            </div>
            <div class="title">
              What's a Mission?
            </div>
            <div class="description">
              A mission is fulfilling the spead of TYT’s service to the community.
            </div>
          </div>
          <div class="box">
            <div class="icon">
              <svg width="24" height="24" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.511825 0.773438C0.405903 0.773559 0.304354 0.81569 0.229455 0.890588C0.154557 0.965487 0.112426 1.06704 0.112305 1.17296V12.5675C0.112305 12.6734 0.154397 12.775 0.229323 12.85C0.304249 12.9249 0.405869 12.967 0.51183 12.967C0.617791 12.967 0.719412 12.9249 0.794337 12.85C0.869263 12.775 0.911356 12.6734 0.911356 12.5675V1.17296C0.911235 1.06703 0.869103 0.965483 0.794202 0.890584C0.719301 0.815685 0.617749 0.773555 0.511825 0.773438Z" />
                  <path
                    d="M9.95286 6.38036C9.83492 6.19795 9.77218 5.98535 9.77218 5.76813C9.77218 5.55091 9.83492 5.33832 9.95286 5.1559L11.317 3.01739C11.3994 2.89298 11.4459 2.74817 11.4511 2.59901C11.4563 2.44985 11.4202 2.30215 11.3467 2.17226C11.183 1.87384 10.8572 1.68848 10.4962 1.68848H1.21735V9.84779H10.4962C10.8572 9.84779 11.183 9.66242 11.3466 9.364C11.4202 9.23412 11.4563 9.08642 11.4511 8.93726C11.4459 8.78809 11.3994 8.64329 11.317 8.51888L9.95286 6.38036ZM8.01731 5.10488L5.85703 7.26278C5.69565 7.42398 5.47686 7.5145 5.24875 7.51444C5.02065 7.51437 4.80191 7.42373 4.64062 7.26244L3.61208 6.23388C3.49309 6.1149 3.42625 5.95352 3.42625 5.78524C3.42625 5.61697 3.49309 5.45559 3.61208 5.3366C3.67099 5.27769 3.74094 5.23095 3.81791 5.19907C3.89489 5.16718 3.9774 5.15077 4.06071 5.15077C4.14403 5.15077 4.22654 5.16718 4.30352 5.19907C4.38049 5.23095 4.45044 5.27769 4.50935 5.3366L5.05008 5.87735C5.10322 5.93049 5.17529 5.96034 5.25043 5.96034C5.32558 5.96034 5.39765 5.93049 5.45078 5.87735L7.12053 4.2076C7.17943 4.1487 7.24936 4.10198 7.32631 4.0701C7.40327 4.03822 7.48575 4.02182 7.56905 4.02182C7.65234 4.02182 7.73482 4.03822 7.81178 4.0701C7.88874 4.10198 7.95866 4.1487 8.01756 4.2076C8.07648 4.26652 8.12321 4.33647 8.15509 4.41346C8.18696 4.49045 8.20336 4.57296 8.20334 4.65629C8.20331 4.73962 8.18687 4.82212 8.15495 4.89909C8.12304 4.97606 8.07627 5.04599 8.01731 5.10488Z" />
                  </svg>
                </div>
                <div class="title">
                  What’s a Task?
                </div>
                <div class="description">
                  Each time you share to a platform, you can complete a task. Completing all the tasks means you’ve won the
                  mission!
                </div>
              </div>
            </div>
            @if (showSignUp) {
              <div class="embedded-signup-container">
                <div class="signup-box">
                  <div class="text">
                    <div class="title">
                      Sign up for Free
                    </div>
                    <div class="subtitle">
                      Have you share count
                    </div>
                    <div class="description">
                      By signing up for a free TYT account, you can complete a mission task, every time you share. Earn badges and
                      reconition when you complete a mission.
                    </div>
                  </div>
                  <div class="action">
                    <button class="cta">
                      Sign up For Free
                    </button>
                    <div class="signin">
                      Already have an Account? <a routerLink="/signin">Sign In</a>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>