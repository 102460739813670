import { ContentBlock, ContentBlockType } from '@tytapp/content';

export interface PetitionProgressBlock extends ContentBlock {
    petitionId: string;
}

export const PETITION_PROGRESS_BLOCK_TYPE: ContentBlockType<PetitionProgressBlock> = {
    id: 'petition-progress',
    label: 'Petition Progress (POG)',
    icon: 'request_quote',
    template: {

    },
    preview: {
        petitionId: 'featured'
    },
    previewSettings: {
        fade: true
    },
    deferredEditorComponent: () => import('./petition-progress-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./petition-progress-block.view').then(m => m.ViewComponent)
};
