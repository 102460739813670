import { Component, OnInit } from '@angular/core';
import { DialogComponent, NamedDialog } from '@tytapp/common';

@NamedDialog('whatIsAMission')
@Component({
  selector: 'tyt-what-is-amission',
  templateUrl: './what-is-a-mission.component.html',
  styleUrls: ['./what-is-a-mission.component.scss']
})
export class WhatIsAMissionComponent extends DialogComponent {

    showSignUp: boolean;

    init(isLogged: boolean) {
        this.showSignUp = isLogged === false;
    }

}
