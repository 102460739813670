import { inject, Injectable } from '@angular/core';
import { ApiCreatePoll, ApiPoll, ApiPollQuestion, ApiSearchResult } from '@tytapp/api';
import { Shell } from '@tytapp/common';

@Injectable()
export class PollsService {
    private shell = inject(Shell);

    isValidForPublishing(poll: ApiCreatePoll | ApiPoll, skippedFields: string[] = []) {
        if (!poll)
            return false;

        let validString = (str: string) => str && str.trim() !== '';
        let atLeastOne = (arr: any[]) => arr.length > 0;
        let questionHasValidChoices = (question: ApiPollQuestion) => {
            if (question.choices.length <= 1)
                return false;

            for (let choice of question.choices) {
                if (!validString(choice.body))
                    return false;
            }

            return true;
        };

        let allQuestionsHaveValidChoices = (questions: ApiPollQuestion[]) => {
            return !questions.some(x => !questionHasValidChoices(x));
        };

        let validate = (components: { [name: string]: (v) => boolean; }) => {
            let invalid = [];
            for (let componentName of Object.keys(components)) {
                let validator = components[componentName];

                if (typeof validator !== 'function') {
                    throw new Error(`Validator for component ${componentName} is not a function.`);
                }

                if (!validator(poll[componentName]) && !skippedFields.includes(componentName))
                    invalid.push(componentName);
            }

            return invalid;
        };

        let invalidComponents = validate({
            title: validString,
            questions: qs => this.canHaveQuestions(poll) ? (atLeastOne(qs) && allQuestionsHaveValidChoices(qs)) : true
        });

        if (invalidComponents.length > 0)
            return false;

        return true;
    }

    canHaveQuestions(poll: ApiCreatePoll | ApiPoll) {
        return poll.type !== 'Petition';
    }

    isValidForDrafting(poll: ApiCreatePoll | ApiPoll) {
        if (!poll)
            return false;

        if (!poll.title || poll.title.trim() === '')
            return false;

        return true;
    }

    async getUrlFromSearchResult(item: ApiSearchResult) {
        await this.shell.waitForFeatures();
        return this.getUrlFromSearchResultSync(item);
    }

    getUrlFromSearchResultSync(item: ApiSearchResult) {
        return `/${
            item.content_type === 'poll' ? 'polls' : 'campaigns'
        }/${
            item.slug || item.uuid
        }`;
    }

    getPollUrl(item: ApiPoll) {
        return `/${
            item.type === 'Petition' ? 'campaigns' : 'polls'
        }/${
            item.slug || item.id
        }`;
    }
}
